import React from 'react';

const Test = () => {
  const users = [
    { name: 'kamran', age: 27 },
    { name: 'ola', age: 15 },
    { name: 'leks', age: 30 },
  ];
  //   const user1 = users[0];
  const keyedUsers = users.reduce((acc, user) => {
    acc[user.name] = user;
    return acc;
  }, {});
  console.log(keyedUsers);
  return (
    <div>
      <h1>Test Page</h1>
      {/* <h3>{obj}</h3> */}
    </div>
  );
};

export default Test;
